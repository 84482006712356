import debounce from 'lodash/debounce';

/**
** Round number with n decimals
*/

export const round = (x, n=2) => {
    const decimals = 10 ** n
    return Math.round(x * decimals)/decimals
}


/*
** Reduced motion var
*/

export const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches


/*
** Add resizEnd event to window
*/

export const resizeEnd = () => {
    const resizeEnd = new Event('resizeEnd');

    window.addEventListener('resize', debounce(() => {
        window.dispatchEvent(resizeEnd)
    }, 100));
}


/*
** Add bodyResize event to window
*/

export const bodyResize = () => {

    let timeout
    const bodyResizeEvent = new CustomEvent('bodyResize');
    new ResizeObserver(() => {

        if(timeout) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(() => {
            window.dispatchEvent(bodyResizeEvent)
        }, 200)
    }).observe(document.body)
}
