<template>
  <component
      v-if="text"
      :is="tag"
      :class="className"
      v-reveal.once="reveal ? revealHandler : false"
  >
    <span
      class="o-at__inner"
      :data-text="text"
      v-html="text"
      ref="text"
    ></span>
  </component>
</template>

<script>

import { gsap, SplitText } from 'src/gsap'

export default {
  name: 'AnimText',
  props: {
    text: {
      type: String,
      default: null,
    },
    tag: {
      type: String,
      default: 'span',
    },
    type: {
      type: String,
      default: null,
    },
    reveal: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    split: false,
    isRevealed: false,
    isRevealing: false,
  }),
  mounted() {

    this.split = new SplitText(this.$refs.text, {
      type: 'lines',
      linesClass: 'o-at__line',
      reduceWhiteSpace: false,
    })

    this.splitWrap = new SplitText(this.$refs.text, {
      type: 'lines',
      linesClass: 'o-at__line-wrap',
      reduceWhiteSpace: false,
    })

    this.hide()
  },
  methods: {
    revealHandler(state) {
      if(state.isActive && !this.isRevealing) {
        this.show()
      } else if(!state.isActive && this.isRevealing) {
        this.hide()
      }
    },
    show() {

      gsap.to(this.split.lines, {
        duration: 1.4,
        yPercent: 0,
        stagger: 0.15,
        ease: 'power3.inOut',
        onStart: () => {
          this.isRevealing = true
        },
        onComplete: () => {
          this.isRevealed = true
          this.isRevealing = false
          this.splitWrap.revert()
          this.split.revert()
          this.$emit('revealed')
        }
      })
    },
    hide() {
      this.isRevealed = false
      this.isRevealing = false

      gsap.set(this.split.lines, {
        yPercent: 100,
      })
    }
  },
  computed: {
    className() {
      let classname = 'o-at'

      if(this.reveal) {
        classname += ' -reveal'

        if(this.isRevealing) {
          classname += ' is-revealing'
        }

        if(this.isRevealed) {
          classname += ' is-revealed'
        }
      }

      if(this.type) {
        classname += ` -${this.type}`
      }

      return classname
    },
  },
  watch: {
    visible(visible) {
      if(visible) {
        this.show()
      } else {
        this.hide()
      }
    },
  }
};

</script>

<style lang="scss">

.o-at {
  display: block;

  &.-glitch,
  &.-glitch-stroke {

    .o-at__inner {
      transform: skewX(calc(var(--s-dir) * var(--s-vel) * -3deg));
      transform-origin: 50% 100%;
      transition: transform .1s ease-out;
      will-change: transform;

      &:before {
        transform: translate(0, calc(var(--s-dir) * var(--s-vel) * -.3em));
      }

      &:after {
        transform: translate(0, calc(var(--s-dir) * var(--s-vel) * -.15em));
      }
    }
  }

  &.-glitch {

    .o-at__inner {

      &:before {
        color: var(--color-glitch-1);
      }

      &:after {
        color: var(--color-glitch-2);
      }
    }
  }

  &.-glitch-stroke {
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--color-primary);

    .o-at__inner {

      &:after,
      &:before {
        color: transparent;
        -webkit-text-stroke-width: 1px;
      }

      &:before {
        -webkit-text-stroke-color: var(--color-glitch-1)
      }

      &:after {
        -webkit-text-stroke-color: var(--color-glitch-2)
      }
    }
  }

  &.is-revealed {

    &.-glitch,
    &.-glitch-stroke {

      .o-at__inner {
        z-index: 0;

        &:after,
        &:before {
          z-index: -1;
          content: attr(data-text);
          position: absolute;
          top: 0;
          left: 0;
          transition: transform .3s ease-out;
          will-change: transform;
        }
      }
    }
  }

  @media #{md("sm")} {

    &.-glitch,
    &.-glitch-stroke {

      .o-at__inner {

        &:before {
          transform: translate(0, calc(var(--s-dir) * var(--s-vel) * -.2em));
        }

        &:after {
          transform: translate(0, calc(var(--s-dir) * var(--s-vel) * -.1em));
        }
      }
    }
  }
}

.o-at__inner {
  display: block;
}

.o-at__line-wrap {
  z-index: 1;
  overflow: hidden;
}

.o-at__line {
  will-change: transform;
}

</style>
