const home = {
    title: 'Lucas.',
    intro: 'Creative Developer since 2014. Specialized in developing interactive experiences with animations and transition driven websites. Part of the Locomotive team and member of the Awwwards Jury Panel.',
}

const projects = [
    {
        title: 'Lowe\'s Innovation Labs',
        year: 2024,
        url: 'https://www.lowesinnovationlabs.com',
        awards: [
            {
                name: 'Awwwards — Site of the day',
                url: 'https://www.awwwards.com/sites/lowes-innovation-labs',
            },
        ],
    },
    {
        title: 'Stenger Bikes',
        year: 2024,
        url: 'https://stenger-bike.de',
        awards: [
            {
                name: 'Awwwards — Site of the day',
                url: 'https://www.awwwards.com/sites/stenger-bike',
            },
            {
                name: 'Awwwards — Developer award',
                url: 'https://www.awwwards.com/sites/stenger-bike',
            },
        ],
    },
    {
        title: 'Hervé Baillargeon',
        year: 2023,
        url: 'https://hervebaillargeon.com',
        awards: [
            {
                name: 'Awwwards — Site of the day',
                url: 'https://www.awwwards.com/sites/herve-baillargeon',
            },
            {
                name: 'Awwwards — Developer award',
                url: 'https://www.awwwards.com/sites/herve-baillargeon',
            },
        ],
    },
    {
        title: 'Modelec',
        year: 2023,
        url: 'https://www.modelec.com',
        awards: [
            {
                name: 'Awwwards — Site of the day',
                url: 'https://www.awwwards.com/sites/modelec',
            },
            {
                name: 'Awwwards — Developer award',
                url: 'https://www.awwwards.com/sites/modelec',
            },
        ],
    },
    {
        title: 'SWTCH',
        year: 2023,
        url: 'https://swtchenergy.com',
        awards: [
            {
                name: 'Awwwards — Honorable Mention',
                url: 'https://www.awwwards.com/sites/swtch',
            },
        ],
    },
    {
        title: 'Carlton Villa',
        year: 2023,
        url: 'https://carltonvilla.com',
        awards: [
            {
                name: 'Awwwards — Honorable Mention',
                url: 'https://www.awwwards.com/sites/carlton-villa',
            },
        ],
    },
    {
        title: 'PP Fragment',
        year: 2022,
        url: 'https://pp-fragment.com',
        awards: [
            {
                name: 'Awwwards — Site of the day',
                url: 'https://www.awwwards.com/sites/pp-r-fragment-1',
            },
            {
                name: 'Awwwards — Site of the Year Nomination',
                url: 'https://www.awwwards.com/sites/pp-r-fragment-1',
            },
            {
                name: 'Awwwards — Developer award',
                url: 'https://www.awwwards.com/sites/pp-r-fragment-1',
            },
            {
                name: 'FWA — Site of the day',
                url: 'https://thefwa.com/cases/ppr-fragment',
            },
            {
                name: 'Idéa Argent — Promotional website',
                url: 'https://concoursidea.ca/projets/pp-fragment',
            },
            {
                name: 'Orpetron — Site of the Day',
                url: 'https://orpetron.com/sites/pp-fragment/',
            },
        ],
    },
    {
        title: 'ADCC Awards',
        year: 2022,
        url: 'https://theadccawards.ca',
        awards: [
            {
                name: 'Awwwards — Site of the day',
                url: 'https://www.awwwards.com/sites/the-2022-adcc-awards-1',
            },
            {
                name: 'Awwwards — Developer award',
                url: 'https://www.awwwards.com/sites/the-2022-adcc-awards-1',
            },
        ],
    },
    {
        title: 'Exo Shop',
        year: 2022,
        url: 'https://exoshop.com',
        awards: [
            {
                name: 'Awwwards — Honorable Mention',
                url: 'https://www.awwwards.com/sites/exo',
            },
            {
                name: 'CSSDA — Special Kudo',
                url: 'https://www.cssdesignawards.com/sites/exo/42427/',
            },
        ],
    },
    {
        title: 'Kode Media',
        year: 2022,
        url: 'https://kodemedia.com',
        awards: [
            {
                name: 'Awwwards — Site of the day',
                url: 'https://www.awwwards.com/sites/kode-media',
            },
            {
                name: 'Awwwards — Developer award',
                url: 'https://www.awwwards.com/sites/kode-media',
            },
            {
                name: 'CSSDA — Special Kudo',
                url: 'https://www.cssdesignawards.com/sites/kode-media/42214/',
            },
        ],
    },
    {
        title: 'Maison SMC',
        year: 2022,
        url: 'https://maisonsmc.org',
        awards: [
            {
                name: 'Idéa Argent — Social website',
                url: 'https://concoursidea.ca/projets/maison-smc-0',
            },
            {
                name: 'Idéa Bronze — UX',
                url: 'https://concoursidea.ca/projets/maison-smc',
            },
        ],
    },
    {
        title: 'Dr. Dabber Experience',
        year: 2022,
        url: 'https://experience.drdabber.com',
        awards: [
            {
                name: 'FWAWWWARD ⚡⚡',
                url: 'https://twitter.com/lucasvallenet/status/1519644943060512769',
            },
            {
                name: 'Awwwards — Site of the day',
                url: 'https://www.awwwards.com/sites/dr-dabber-experience',
            },
            {
                name: 'Awwwards — Developer award',
                url: 'https://www.awwwards.com/sites/dr-dabber-experience',
            },
            {
                name: 'FWA — Site of the day',
                url: 'https://thefwa.com/cases/dr-dabber-experience',
            },
            {
                name: 'Idéa Bronze — Transactional',
                url: 'https://concoursidea.ca/projets/dr-dabber-experience',
            },
        ],
    },
    {
        title: 'Study Hall Creative',
        year: 2022,
        url: 'https://studyhall.design',
        awards: [
            {
                name: 'Awwwards — Site of the day',
                url: 'https://www.awwwards.com/sites/study-hall-design-studio',
            },
            {
                name: 'Awwwards — Developer award',
                url: 'https://www.awwwards.com/sites/study-hall-design-studio',
            },
        ],
    },
    {
        title: 'Cyberphotos',
        year: 2021,
        url: 'https://cyberphotos.ca',
        awards: [
            {
                name: 'Idéa Argent — Front-end Development',
                url: 'https://concoursidea.ca/projets/cyberphotos',
            },
            {
                name: 'Awwwards — Site of the day',
                url: 'https://www.awwwards.com/sites/cyberphotos',
            },
            {
                name: 'Awwwards — Developer award',
                url: 'https://www.awwwards.com/sites/cyberphotos',
            },
            {
                name: 'Awwwards — Mobile Excellence',
                url: 'https://www.awwwards.com/sites/cyberphotos/mobile-excellence-report',
            },
            {
                name: 'CSSDA — Site of the Day',
                url: 'https://www.cssdesignawards.com/sites/cyberphotos/39095/',
            },
        ],
    },
    {
        title: 'Outsmart Labs',
        year: 2021,
        url: 'https://outsmartlabs.com',
        awards: [
            {
                name: 'Awwwards — Honorable Mention',
                url: 'https://www.awwwards.com/sites/outsmart-labs',
            },
            {
                name: 'Awwwards — Mobile Excellence',
                url: 'https://www.awwwards.com/sites/outsmart-labs/mobile-excellence-report',
            },
            {
                name: 'CSSDA — Site of the day',
                url: 'https://www.cssdesignawards.com/sites/outsmart-labs/38480/',
            },
        ]
    },
    {
        title: 'Silvia Tcherassi',
        year: 2020,
        url: 'https://www.silviatcherassi.com',
        awards: [
            {
                name: 'Awwwards — Honorable Mention',
                url: 'https://www.awwwards.com/sites/silvia-tcherassi',
            },
            {
                name: 'Awwwards — Mobile Excellence',
                url: 'https://www.awwwards.com/sites/silvia-tcherassi/mobile-excellence-report',
            },
        ]
    },
    {
        title: 'MON+',
        year: 2020,
        url: 'https://monplus.ca',
        awards: [
            {
                name: 'Awwwards — Honorable Mention',
                url: 'https://www.awwwards.com/sites/mon',
            },
            {
                name: 'Awwwards — Mobile Excellence',
                url: 'https://www.awwwards.com/sites/mon/mobile-excellence-report',
            },
        ]
    },
    {
        title: 'Chasse Québec',
        year: 2020,
        url: 'https://chassequebec.com',
        awards: [
            {
                name: 'Awwwards — Honorable Mention',
                url: 'https://www.awwwards.com/sites/chasse-quebec',
            },
            {
                name: 'Awwwards — Mobile Excellence',
                url: 'https://www.awwwards.com/sites/chasse-quebec/mobile-excellence-report',
            },
        ]
    },
    {
        title: 'Mille et un',
        year: 2020,
        url: 'https://www.fondsmilleetun.com',
        awards: [
            {
                name: 'Awwwards — Honorable Mention',
                url: 'https://www.awwwards.com/sites/mille-et-un-fund',
            },
            {
                name: 'Awwwards — Mobile Excellence',
                url: 'https://www.awwwards.com/sites/mille-et-un-fund/mobile-excellence-report',
            },
            {
                name: 'CSSDA — Special Kudo',
                url: 'https://www.cssdesignawards.com/sites/mille-et-un-fund/36607/',
            },
        ]
    },
    {
        title: 'Colibri',
        year: 2020,
        url: 'https://restaurantcolibri.ca',
        awards: [
            {
                name: 'Awwwards — Honorable Mention',
                url: 'https://www.awwwards.com/sites/restaurant-colibri',
            },
            {
                name: 'Awwwards — Mobile Excellence',
                url: 'https://www.awwwards.com/sites/restaurant-colibri/mobile-excellence-report',
            },
            {
                name: 'CSSDA — Special Kudo',
                url: 'https://www.cssdesignawards.com/sites/restaurant-colibri/36494/',
            },
        ]
    },
    {
        title: 'Vivre en ville',
        year: 2020,
        url: 'https://localisation-ecoresponsable.com',
        awards: [
            {
                name: 'Awwwards — Honorable Mention',
                url: 'https://www.awwwards.com/sites/localisation-ecoresponsable',
            },
            {
                name: 'CSSDA — Special Kudo',
                url: 'https://www.cssdesignawards.com/sites/localisation-coresponsable/36549/',
            },
        ]
    },
    {
        title: 'Le mois',
        year: 2019,
        url: 'https://mois-es.com',
        vimeoID: '408374418',
        awards: [
            {
                name: 'Awwwards — Honorable Mention',
                url: 'https://www.awwwards.com/sites/mois-de-leconomie-sociale',
            },
            {
                name: 'Awwwards — Mobile Excellence',
                url: 'https://www.awwwards.com/sites/mois-de-leconomie-sociale/mobile-excellence-report',
            },
            {
                name: 'CSSDA — Special Kudo',
                url: 'https://www.cssdesignawards.com/sites/mois-de-leconomie-sociale/36116/',
            },
        ]
    },
    {
        title: 'Hono Izakaya',
        year: 2019,
        url: 'http://honoizakaya.ca',
        vimeoID: '395820804',

        awards: [
            {
                name: 'Awwwards — Honorable Mention',
                url: 'https://www.awwwards.com/sites/hono-izakaya',
            },
            {
                name: 'Awwwards — Mobile Excellence',
                url: 'https://www.awwwards.com/sites/hono-izakaya/mobile-excellence-report',
            },
            {
                name: 'CSSDA — Special Kudo',
                url: 'https://www.cssdesignawards.com/sites/hon-izakaya/36241/',
            },
        ]
    },
    {
        title: 'Meta music',
        year: 2019,
        url: 'https://metamusic.ca',
        awards: [
            {
                name: 'Awwwards — Honorable Mention',
                url: 'https://www.awwwards.com/sites/metamusic',
            },
            {
                name: 'CSSDA — Special Kudo',
                url: 'https://www.cssdesignawards.com/sites/metamusic/36203/',
            },
            // {
            //   name: 'Communications Arts — Honorable',
            //   url: 'https://www.cssdesignawards.com/sites/metamusic/36203/',
            // },
        ]
    },
    {
        title: 'Strato automation',
        year: 2019,
        url: 'http://www.stratoautomation.com',
        awards: [
            {
                name: 'Awwwards — Honorable Mention',
                url: 'https://www.awwwards.com/sites/strato-automation',
            },
        ]
    },
    {
        title: 'Lateral innovation',
        year: 2019,
        url: 'https://lateralinnovations.com',
        awards: [
            {
                name: 'Awwwards — Honorable Mention',
                url: 'https://www.awwwards.com/sites/lateral-innovations',
            },
            {
                name: 'CSSDA — Special Kudo',
                url: 'https://www.cssdesignawards.com/sites/lateral-innovations/36072/',
            },
        ]
    },
    {
        title: 'Pacific & Co',
        year: 2017,
        url: 'http://pacifico.lucasvallenet.com',
        vimeoID: '242626477',
        awards: [
            {
                name: 'Awwwards — Site of the day',
                url: 'https://www.awwwards.com/sites/performance-pacific-co',
            },
        ]
    },
    {
        title: 'Herokid studio',
        year: 2017,
        url: 'http://www.herokidstudio.es',
        awards: [
            {
                name: 'Awwwards — Honorable Mention',
                url: 'https://www.awwwards.com/sites/herokid-studio',
            },
            {
                name: 'CSSDA — Special Kudo',
                url: 'https://www.cssdesignawards.com/sites/herokid-studio/30297/',
            },
        ]
    },
    {
        title: 'Lana papier',
        year: 2016,
        url: 'http://www.lanapapier.fr',
        vimeoID: '395820804',
        awards: [
            {
                name: 'FWA — Site of the day',
                url: 'https://thefwa.com/cases/lana',
            },
            {
                name: 'Awwwards — Honorable Mention',
                url: 'https://www.awwwards.com/sites/lana',
            },
            {
                name: 'CSSDA — Site of the day',
                url: 'https://www.cssdesignawards.com/sites/lana/29258/',
            },
        ]
    },
    {
        title: 'Vins d\'alsace',
        year: 2015,
        url: 'https://www.vinsalsace.com',
        awards: [
            {
                name: 'CSSDA — Special Kudo',
                url: 'https://www.cssdesignawards.com/sites/vins-d-alsace/27247/',
            },
        ]
    },
    {
        title: 'Signes du quotidien',
        year: 2015,
        url: 'https://www.signesduquotidien.org',
        awards: [
            {
                name: 'Awwwards — Site of the day',
                url: 'https://www.awwwards.com/sites/signes-du-quotidien',
            },
            {
                name: 'Awwwards — Developer award',
                url: 'https://www.awwwards.com/sites/signes-du-quotidien',
            },
        ]
    },
]

const socials = [
    {
        name: 'email',
        title: 'Send Email',
        url: 'mailto:lucasvallenet@gmail.com',
    },
    {
        name: 'codepen',
        title: 'Discover Codepens',
        url: 'https://codepen.io/lucasvallenet',
    },
    {
        name: 'linkedin',
        title: 'Connect in LinkedIn',
        url: 'https://www.linkedin.com/in/lucasvallenet/',
    },
]

export {
    home,
    projects,
    socials,
}
