import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger, SplitText, ScrollToPlugin)

ScrollTrigger.normalizeScroll(true)
ScrollTrigger.config({ ignoreMobileResize: true })

export * from 'gsap'
export { ScrollTrigger, SplitText }

window.addEventListener('bodyResize', ScrollTrigger.refresh)
