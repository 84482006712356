<template>
  <div
    class="l-wrapper"
    :class="className"
  >
    <main class="l-main">
      <home />
    </main>
    <app-footer @update-mode="updateMode" />
    <!-- <app-cookies /> -->
    <span
      v-if="firstLoad"
      ref="overlay"
      class="o-overlay"
    ></span>
  </div>
</template>

<script>

import AppFooter from 'layout/AppFooter';
// import AppCookies from 'layout/AppCookies';
import Home from 'views/Home';

import { round } from 'utils'
import EventBus from 'src/event-bus'

import { gsap, ScrollTrigger } from 'src/gsap'

export default {
  name: 'AppBase',
  components: {
    AppFooter,
    // AppCookies,
    Home,
  },
  data: () => ({
    mode: 'dark',
    perc: 0,
    scrollPerc: 0,
    scrollVel: 0,
    scrollDir: 0,
    isScrolling: false,
    firstLoad: true,
  }),
  mounted() {

    // First animation
    this.firstAnimation()

    this.setFavicon('✌️')

    // Set sizes
    this.setSizes()

    // Scroll trigger
    let proxy = { velocity: 0 }
    let clamp = gsap.utils.clamp(-1, 1);

    const wrapperStyles = this.$el.style

    // Scroll trigger
    ScrollTrigger.create({
      trigger: this.$el,
      start: 'top top',
      end: 'bottom bottom',
      onUpdate: (self) => {

        // Update percentage
        this.scrollPerc = round(self.progress, 2)

        let velocity = clamp(self.getVelocity() / -300);
        if (Math.abs(velocity) > Math.abs(proxy.velocity)) {
          proxy.velocity = velocity;
          gsap.to(proxy, {
            velocity: 0,
            snap: {
              velocity: 0.001,
            },
            duration: 0.4,
            ease: 'power3.out',
            overwrite: true,
            onStart: () => this.isScrolling = true,
            onUpdate: () => {
              this.scrollVel = Math.abs(proxy.velocity)
              this.scrollDir = proxy.velocity < 0 ? 1 : proxy.velocity > 0 ? -1 : 0

              wrapperStyles.setProperty('--s-vel', this.scrollVel);
              wrapperStyles.setProperty('--s-dir', this.scrollDir);
            },
            onComplete: () => this.isScrolling = false,
          });
        }
      }
    });

    // window events
    window.addEventListener('resizeEnd', this.setSizes)
  },
  computed: {
    className() {
      let classname = `-mode-${this.mode}`

      return classname
    },
  },
  methods: {
    setSizes() {
      let vh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    updateMode(dark) {
      this.mode = dark ? 'dark' : 'light'
    },
    firstAnimation() {

      let delay = new Date()
      delay = (delay - window.timestamp)
      delay = delay > 1000 ? 0 : (1000 - delay)/1000

      const $overlay = this.$refs.overlay
      gsap.to($overlay, {
        duration: 1.2,
        scaleY: 0,
        delay,
        ease: 'expo.inOut',
        onStart: () => {
          setTimeout(() => {
            EventBus.$emit('loaderEnd')
          }, 600);
        },
        onComplete: () => {
          this.firstLoad = false

          document.documentElement.classList.remove('-firstload')
        },
      })
    },
    setFavicon(icon) {
      icon = `
        <svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22>
          <text y=%22.9em%22 font-size=%2290%22>
            ${icon}
          </text>
        </svg>
      `.trim();

      const linkForFavicon = document.querySelector(`head > link[rel='icon']`);
      linkForFavicon.setAttribute('href', `data:image/svg+xml,${icon}`);
    }
  },
  watch: {
    isScrolling(scrolling) {
      if(!scrolling) {
        const icon = this.scrollPerc < 1 ? '✌️' : '🤯'
        this.setFavicon(icon)
      }
    },
    scrollDir(dir) {
      if(dir > 0) {
        this.setFavicon('⬇')
      } else if(dir < 0) {
        this.setFavicon('⬆')
      }
    }
  }
}

</script>

<style lang="scss">


.l-wrapper {
  color: var(--color-primary);
  background-color: var(--color-bg);
  @include noise;
  transition: color .3s ease-out .3s, background-color .3s ease-in;

  /*----------  Subsection comment block  ----------*/

  &.-mode-dark {
    --color-primary:    #{$color-light};
    --color-secondary:  #{$color-grey-dark};
    --color-bg:         #{$color-dark};

    --color-glitch-1 :  #{$color-blue};
    --color-glitch-2 :  #{$color-green};
  }

  &.-mode-light {
    --color-primary:    #{$color-dark};
    --color-secondary:  #{$color-grey-dark};
    --color-bg:         #{$color-light};

    --color-glitch-1 :  #{$color-red};
    --color-glitch-2 :  #{$color-blue};
  }
}

.l-main {
}

.o-overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--color-primary);
  transform-origin: 50% 0;
  will-change: transform;
}

/*----------  Animation  ----------*/

@keyframes noise {
  0% {
    transform: translate(0, 9rem)
  }
  10% {
    transform: translate(-1rem, -4rem)
  }
  20% {
    transform: translate(-8rem, 2rem)
  }
  30% {
    transform: translate(9rem, -9rem)
  }
  40% {
    transform: translate(-2rem, 7rem)
  }
  50% {
    transform: translate(-9rem, -4rem)
  }
  60% {
    transform: translate(2rem, 6rem)
  }
  70% {
    transform: translate(7rem, -8rem)
  }
  80% {
    transform: translate(-9rem, 1rem)
  }
  90% {
    transform: translate(6rem, -5rem)
  }
  100% {
    transform: translate(-7rem, 0)
  }
}


</style>
