import Vue from 'vue'

import { ScrollTrigger } from 'src/gsap'

import EventBus from 'src/event-bus'

const revealClass = new class Reveal {

  constructor() {
    // console.log(`${this.constructor.name}:init`)

    this.classname = {
      reveal: 'js-reveal',
      visible: 'is-visible',
    }

    this.items = {}

    this.isEnabled = false

    // Watch load state to enable/disable reveals
    EventBus.$on('loaderStart', this.disable.bind(this))
    EventBus.$on('loaderEnd', this.enable.bind(this))
  }

  refreshTriggers() {
    // console.log(`${this.constructor.name}:refreshTriggers`)

    for(let id in this.items) {
     this.items[id].trigger.refresh(true)
    }
  }

  addTrigger(id, item) {
    // console.log(`${this.constructor.name}:addTrigger`)

    item.$el.classList.add(this.classname.reveal)

    this.items[id] = {
      id: item.id,
      $el: item.$el,
      cb: item.cb,
      once: item.once,
      trigger: ScrollTrigger.create({
          trigger: item.$el,
          start: 'top 90%',
          end: 'bottom 10%',
          onUpdate: (self) => this.updateItem(id, self),
          once: item.once,
      })
    }

    if(!this.isEnabled) {
      this.items[id].trigger.disable()
    }
  }

  updateItem(id, state) {
    // console.log(`${this.constructor.name}:updateItem`, id, state, this.isEnabled)

    const item = this.items[id]

    if(!this.isEnabled) {
      return
    }

    if (item.cb) {
      item.cb(state)
    }

    if(state.isActive) {
      item.$el.classList.add(this.classname.visible)
    } else {
      item.$el.classList.remove(this.classname.visible)
    }

    if(item.once) {
      this.removeTrigger(item.id)
    }
  }

  removeTrigger(id) {
    // console.log(`${this.constructor.name}:removeTrigger`)

    const item = this.items[id]

    item.trigger.kill()

    setTimeout(() => {
      item.$el.classList.remove(this.classname.reveal)
      item.$el.classList.remove(this.classname.visible)
      delete item.$el.dataset.reveal
    }, 2000);

    delete this.items[id]
  }

  enable() {
    // console.log(`${this.constructor.name}:enable`)

    this.isEnabled = true

    let item
    for(let id in this.items) {
      item = this.items[id]
      item.trigger.enable()
      if(item.trigger.isActive) {
        this.updateItem(id, item.trigger)
      }
    }
  }

  disable() {
      // console.log(`${this.constructor.name}:disable`)

      for(let id in this.items) {
        this.items[id].trigger.disable()
      }

      this.isEnabled = false
  }

  destroy() {
      // console.log(`${this.constructor.name}:destroy`)

      EventBus.$off('bodyHeightUpdate', this.refreshTriggers)
      EventBus.$off('loaderStart')
      EventBus.$off('loaderEnd')
  }
}

let revealIndex = 0
const reveal = Vue.directive('reveal', {
  inserted: function($el, bind) {
    const arg = bind.value

    // Return if argument is set to false
    if(arg === false) {
      return
    }

    // Define item id
    let id = $el.dataset.reveal || revealIndex++

    // Set default item values
    let item = revealClass.items[id] || {
      id,
      $el,
      cb: typeof arg === 'function' ? arg : false,
      once: !!bind.modifiers.once,
    }

    $el.dataset.reveal = id

    revealClass.addTrigger(id, item)
  },
  unbind: function($el, bind) {

    // Return if argument is set to false
    if(bind.value === false) {
      return
    }

    const id = Number($el.dataset.reveal)

    revealClass.removeTrigger(id)
  }
})

export default reveal
