<template>
    <div>
        <app-section
        tag="article"
        >
        <template #before>
            <div
            class="p-home__intro"
            ref="header"
            :style="`--scroll-progress: ${headerProgress};`"
            :class="{ 'is-ended' : headerProgress === 1}"
            >
            <anim-text
            tag="h1"
            :text="home.title"
            :type="headerProgress === 1 ? 'glitch-stroke' : 'glitch'"
            class="p-home__title | t-t1"
            ref="title"
            />
            <icon
            class="p-home__smiley | t-t1"
            :style="headerClipPath"
            icon="smiley"
            />
        </div>
    </template>

    <div class="p-home__desc">
        <span
        class="o-line js-reveal"
        v-reveal.once
        ></span>

        <anim-text
        tag="p"
        :text="home.intro"
        />
    </div>

</app-section>

<!-- <slider /> -->

<awards />

</div>
</template>

<script>

import AppSection from 'layout/AppSection';
import Icon from 'objects/Icon';
import AnimText from 'objects/AnimText';
import Awards from 'components/Awards';
// import Slider from 'components/Slider';

import { home } from 'src/data';
import { round } from 'src/utils';

import { ScrollTrigger } from 'src/gsap'

export default {
    name: 'Home',
    components: {
        AppSection,
        Icon,
        AnimText,
        // Slider,
        Awards,
    },
    data: () => ({
        home,
        headerProgress: 0,
        headerClipPath: 0,
    }),
    mounted() {

        // gsap.to($title, {
        //   scrollTrigger: {
        //     trigger: this.$refs.header,
        //     start: 'top top',
        //     end: '+=300%',
        //     scrub: 0,
        //     pin: $title,
        //     pinSpacing: false,
        //   }
        // })

        ScrollTrigger.create({
            trigger: this.$refs.header,
            start: 'top top',
            end: 'bottom bottom',
            scrub: 2,
            onUpdate: self => {
                this.headerProgress = round(self.progress, 3)
                this.setHeaderClipPath(this.headerProgress * 100)
            },
        })
    },
    methods: {
        setHeaderClipPath(perc) {

            // Define default polygon
            let polygon = [
            // Center
            [50, 50],
            // Top right corner
            [100, 0],
            // Bottom right corner
            [100, Math.min(round(4 * perc), 100)],
            ]

            let x, y

            // Bottom left corner
            if (perc >= 25) {
                x = Math.max(round(-4 * perc + 200), 0)
                y = 100
                polygon.push([x, y])
            }

            // Top left corner
            if (perc >= 50) {
                x = 0
                y = Math.max(round(-4 * perc + 300), 0)
                polygon.push([x, y])
            }

            // Top right corner
            if (perc >= 75) {
                x = Math.min(round(4 * perc - 300), 100)
                y = 0
                polygon.push([x, y])
            }

            polygon = polygon.map(p => p.join('% ').concat('%')).join(', ');

            // Array to string with css polygon format values
            this.headerClipPath = `clip-path: polygon(${polygon});`
        },
    },
};

</script>

<style lang="scss">

.p-home__intro {
    width: 100%;
    height: vh(300);
    padding-top: var(--main-gutter);
    padding-bottom: var(--main-gutter);

    &.is-ended {

        .p-home__title {
            position: absolute;
            top: auto;
            bottom: var(--main-gutter);
            left: 0;
            transform: none;
        }

        .p-home__smiley {
            position: absolute;
            right: 0;
            opacity: 1;
            transform: rotate(0);
            clip-path: none;

            svg {
                clip-path: none;
            }
        }
    }
}

.p-home__title {
    position: fixed;
    top: var(--main-gutter);
    left: var(--main-gutter);
    transform: translate(0, calc(var(--scroll-progress) * (#{vh(100)} - var(--main-gutter) * 2 - 100%)));
}

.p-home__smiley {
    --icon-size: .8em;

    position: fixed;
    bottom: var(--main-gutter);
    right: var(--main-gutter);
    display: flex;
    transform: rotate(calc(-180deg - 45deg + 180deg * var(--scroll-progress)));
    opacity: calc(var(--scroll-progress)/8);
    transition: opacity .3s ease-out, transform .6s $out-quad, color .4s ease-out;

    svg {
        fill: currentColor;
        clip-path: polygon(0% 0%, 0% 100%, 22% 100%, 22% 22%, 78% 22%, 78% 82%, 22% 82%, 22% 100%, 100% 100%, 100% 0%);
    }
}

.p-home__desc {
    line-height: 1.8;

    .o-line {
        margin-bottom: var(--main-gutter-half);
    }

    @media #{md("xs")} {
        width: calc(3/4 * 100%);
        margin-left: auto;
    }

    @media #{md("sm")} {
        width: calc(1/3 * 100%);
    }
}

</style>
