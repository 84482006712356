<template>
  <footer class="l-footer">
    <div class="grid">
      <span class="o-line" v-reveal.once></span>
      <div class="l-footer__inner">
        <div class="l-footer__buttons">
          <btn
            label="Scroll top"
            iconBefore="arrow-up"
            class="l-footer__scroll"
            @click.native="scrollTop"
          />
          <btn
            :label="darkMode ? 'Light' : 'Dark'"
            @click.native="updateMode"
            @mouseenter.native="toggleMode(!darkMode)"
            @mouseleave.native="toggleMode(darkMode)"
          />
        </div>
        <ul class="l-footer__links">
          <li
          v-for="(social, i) in socials"
          :key="`social-${i}`"
          >
            <btn
              tag="a"
              :label="social.name"
              iconAfter="arrow-up-right"
              :href="social.url"
              :title="social.title"
              target="_blank"
              rel="noopener"
              @click.native="scrollTop"
            />
          </li>
        </ul>
        <anim-text
          tag="span"
          :text="`&copy;${currentYear}`"
          class="l-footer__copy | t-t2"
        />
      </div>
    </div>
  </footer>
</template>

<script>

import AnimText from 'objects/AnimText';
import Btn from 'components/Btn';

import { socials } from 'src/data'

import { gsap } from 'src/gsap'


export default {
  name: 'AppFooter',
  components: {
    AnimText,
    Btn,
  },
  data: () => ({
    socials,
    darkMode: true,
  }),
  computed: {
    currentYear: () => new Date().getFullYear().toString().substr(2,2),
  },
  methods: {
    scrollTop() {
      gsap.to(document.documentElement, {
        duration: 3,
        scrollTo: {
          y: 0
        },
        ease: 'power3.inOut',
      })
    },
    toggleMode(mode) {
      this.$emit('update-mode', mode)
    },
    updateMode() {
      this.darkMode = !this.darkMode
      this.toggleMode(this.darkMode)
    }
  }
};

</script>

<style lang="scss">

.l-footer {
  padding-bottom: var(--main-gutter);
}

.l-footer__inner {
  padding-top: var(--main-gutter);

  @media #{md("xs")} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.l-footer__links {
  @include reset-list;

  li {
    display: flex;

    &:not(:first-child) {
      margin-top: .5em;
    }
  }

  @media #{md("xs", "max")} {
    padding-top: var(--main-gutter);
  }

  @media #{md("xs")} {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
}

.l-footer__buttons {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media #{md("xs", "max")} {

    button:not(:first-child) {
      margin-top: .25em;
    }
  }

  @media #{md("xs")} {
    justify-content: space-between;
  }
}

.l-footer__scroll {
  left: -1.1em;
}

.l-footer__copy {
  display: flex;
  justify-content: flex-end;

  .-mode-dark & {
    mix-blend-mode: overlay;
    transition: none;
  }

  .-mode-light & {
    mix-blend-mode: exclusion;
    transition: color .3s ease-out;
  }

  @media #{md("xs", "max")} {
    position: absolute;
    right: 0;
    bottom: -1px;
  }

  @media #{md("xs")} {
    text-align: right;
  }
}


</style>
