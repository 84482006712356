import Vue from 'vue'
import App from './App.vue'
import reveal from './directives/reveal' // eslint-disable-line no-unused-vars
import { resizeEnd, bodyResize } from 'src/utils';

// Window scroll events
// import { scrollstart, scrollend } from '@lucasvallenet/js-custom-events';
// scrollstart()
// scrollend()


// Add window resizeEnd event
resizeEnd()

// Add window bodyResize event
bodyResize()


// Production Tip
Vue.config.productionTip = false

// Create Vue App instance
new Vue({
    render: h => h(App),
    mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
