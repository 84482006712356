<template>
  <component
    :is="tag"
    class="l-section"
  >
    <div class="grid">
      <slot name="before" />

      <div class="l-section__inner">
        <anim-text
          v-if="title"
          tag="h2"
          :text="title"
          class="l-section__title | t-t4"
        />
        <div
          v-if="!!$slots.default"
          class="l-section__content"
        >
          <slot />
        </div>
      </div>
    </div>
  </component>
</template>

<script>

import AnimText from 'objects/AnimText';

export default {
  name: 'AppSection',
  components: {
    AnimText,
  },
  props: {
    tag: {
      type: String,
      default: 'section',
    },
    title: {
      default: false,
    },
  },
};

</script>

<style lang="scss">

.l-section {
}

.l-section__header,
.l-section__inner {
  width: 100%;
  padding-top: var(--main-gutter);
  padding-bottom: var(--main-gutter);
}

// .l-section__inner {}

// .l-section__content {}

.l-section__title {
  display: inline-block;
  padding-bottom: vh(5);
}

</style>
