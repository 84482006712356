<template>
  <tag
    :is="tag"
    :href="tag === 'a' && href !== null ? href : false"
    :to="tag === 'router-link' && href !== null ? href : false"
    :class="className"
    v-reveal.once="reveal ? revealHandler : false"
  >
    <icon
      v-if="iconBefore"
      class="c-btn__icon -before"
      :icon="iconBefore"
    />
    <span
      v-if="label || !!$slots.default"
      class="c-btn__label"
    >
      <slot>
        <anim-text
          ref="label"
          :reveal="false"
          :text="label"
        />
      </slot>
    </span>
    <icon
      v-if="iconAfter"
      class="c-btn__icon -after"
      :icon="iconAfter"
    />
  </tag>
</template>

<script>

import Icon from 'objects/Icon';
import AnimText from 'objects/AnimText';

export default {
  name: 'Btn',
  components: {
    Icon,
    AnimText,
  },
  props: {
    tag: {
      type: String,
      default: 'button',
    },
    href: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    iconBefore: {
      type: String,
      default: null,
    },
    iconAfter: {
      type: String,
      default: null,
    },
    reveal: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    className() {
      let classname = 'c-btn | t-t4'

      return classname
    },
  },
  methods: {
    revealHandler(state) {
      if(state.isActive) {
        this.$refs.label.show()
      } else if(!state.isActive) {
        this.$refs.label.hide()
      }
    },
  },
}
</script>

<style lang="scss">

.c-btn {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover {

    .c-btn__label {

      &:after {
        transform: scale(0, 1);
        transform-origin: 100% 50%;
      }
    }

    .c-btn__icon {

      &.-arrow-up-right {
        opacity: 1;
        transform: scale(1);
        transform-origin: 0 100%;
        transition: transform .6s $out-quint, opacity 0s 0s;
      }
    }
  }

  &.js-reveal {

    .c-btn__label {

      &:after {
        transform: scale(0, 1);
      }
    }

    .c-btn__icon {

      &.-arrow-up {
        opacity: 0;
        transform: translate(0, 100%) scale(1);
        transform-origin: 50% 100%;
        transition: opacity .6s $out-quint, transform .6s $out-quint;
        will-change: opacity, transform;
      }
    }

    &.is-visible {

      .c-btn__label {

        &:after {
          transform: scale(1);
          transition-delay: 1.4s;
        }
      }

      .c-btn__icon {

        &.-arrow-up {
          opacity: 1;
          transform: translate(0) scale(1);
          transition-delay: 1.4s;
        }
      }
    }
  }
}

.c-btn__label {

  &:after {
    @include pseudo-el($height: 1px, $bg: currentColor);
    position: absolute;
    bottom: 0;
    left: 0;
    transition: transform .6s $out-quint;
    transform-origin: 0 50%;
  }
}

.c-btn__icon {
  --icon-size: .7em;

  // Position
  &.-before {
    margin-right: .5em;
  }

  &.-after {
    margin-left: .5em;
  }

  // Type
  &.-arrow-up-right {
    opacity: 0;
    transform-origin: 100% 0;
    transform: scale(.1);
    will-change: transform;
    transition: transform .6s $out-quint, opacity 0s .6s;
  }
}

</style>
