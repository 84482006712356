<template>
  <app-section
    title="Awards & recognitions"
    class="c-awards"
  >
    <div
      ref="container"
      class="c-awards__inner"
    >
      <div
        class="c-awards__col -year"
        :style="`--scroll-progress: ${scrollProgress};`"
      >
        <h3
          class="c-awards__year | t-t2"
          :class="{ 'is-sticky' : isSticky }"
        >
          <anim-text
            :text="`/${activeYear}`"
            :type="isSticky ? 'glitch' : 'glitch-stroke'"
          />
        </h3>
      </div>
      <div class="c-awards__col -list">
        <span class="o-line" v-reveal.once></span>
        <ul class="c-awards__list">
          <template v-for="(project, i) in awardProjects">
            <li
              v-for="(award, j) in project.awards"
              :key="`award-${i}-${j}`"
              class="c-awards__item"
              :data-year="(project.year && j === 0) ? project.year : false"
              :ref="`item-${i}-${j}`"
            >
              <a
                :href="award.url"
                :title="project.title"
                target="_blank"
                rel="noopener"
                class="c-awards__link"
              >
                <anim-text
                  tag="h4"
                  :text="project.title"
                  class="c-awards__project"
                />
                <anim-text
                  :text="award.name"
                  class="c-awards__label"
                />
                <icon
                  icon="arrow-right"
                  class="c-awards__icon"
                />
              </a>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </app-section>
</template>

<script>

import AppSection from 'layout/AppSection';
import Icon from 'objects/Icon';
import AnimText from 'objects/AnimText';

import { projects } from 'src/data';
import { round } from 'src/utils';

import { gsap, ScrollTrigger } from 'src/gsap'

export default {
  name: 'Awards',
  components: {
    AppSection,
    Icon,
    AnimText,
  },
  data: () => ({
    projects,
    isSticky: false,
    activeYear: '24',
    scrollProgress: 0,
  }),
  mounted() {

    const $container = this.$refs.container
    this.$year = $container.querySelector('.c-awards__year')
    this.$list = $container.querySelector('.c-awards__list')
    this.$allTriggers = gsap.utils.toArray('[data-year]')
    this.totalTriggers = this.$allTriggers.length

    this.setSizes()

    // window events
    window.addEventListener('resizeEnd', this.setSizes)

    // Init scroll to 0
    this.scrollUpdate(0)

    ScrollTrigger.matchMedia({

      // Mobile
      "(max-width: 767px)": () => {
        ScrollTrigger.create({
          trigger: $container,
          start: 'top 0',
          end: `bottom ${this.yearHeight}`,
          scrub: 0,
          pin: this.$year,
          pinSpacing: false,
          onToggle: self => this.isSticky = self.isActive,
          onUpdate: self => this.scrollUpdate(self.progress),
        })
      },

      // Desktop
      "(min-width: 768px)": () => {
        ScrollTrigger.create({
          trigger: $container,
          start: 'top 25%',
          end: 'bottom 75%',
          scrub: 0,
          pin: this.$year,
          onToggle: self => this.isSticky = self.isActive,
          onUpdate: self => this.scrollUpdate(self.progress),
        })
      },
    })
  },
  computed: {
    awardProjects() {
      let projects = this.projects.filter(project => project.awards && project.awards.length > 0).sort();
      let year = 0
      projects.forEach(project => {
        if (project.year !== year) {
          year = project.year
          project.year = year.toString().substr(-2)
        } else {
          project.year = false
        }
      })

      return projects
    },
  },
  methods: {
    setSizes() {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
      this.mobileLayout = this.windowWidth < 768
      this.yearHeight = this.$year.offsetHeight
      this.listHeight = this.$list.offsetHeight

      this.$triggers = []
      this.$allTriggers.forEach($trigger => {
        this.$triggers.push({
          $el: $trigger,
          offsetTop: $trigger.offsetTop,
          year: $trigger.dataset.year
        })
      })
    },
    scrollUpdate(progress) {
      this.scrollProgress = round(progress, 3)

      let scrollListHeight = this.mobileLayout ? round(this.scrollProgress * this.listHeight) : round(this.scrollProgress * (this.listHeight - this.yearHeight/2))

      let trigger
      let nextTrigger
      for (let i = 0; i < this.totalTriggers; i++) {
        trigger = this.$triggers[i]
        nextTrigger = i === this.totalTriggers - 1 ? false : this.$triggers[i+1]

        if(trigger.offsetTop - scrollListHeight < 0
          && (!nextTrigger || nextTrigger.offsetTop - scrollListHeight > 0)) {

          this.activeYear = trigger.year
          break
        }
      }
    },
  },
  beforeDestroy() {

    // window events
    window.removeEventListener('resizeEnd', this.setSizes)
  }
};

</script>

<style lang="scss">

.c-awards {
}

.c-awards__inner {
  width: 100%;
  margin-bottom: vh(15);

  @media #{md("sm")} {
    display: grid;
    grid-template-columns: 1fr 3fr;
    // grid-column-gap: 2em;
  }

  @media #{md("md")} {
    grid-template-columns: 1fr 2fr;
  }
}

.c-awards__col {

  @media #{md("sm", "max")} {

    &.-list {

      .o-line {
        display: none;
      }
    }
  }

  @media #{md("sm")} {

    &.-year {

      .o-line {
        display: none;
      }
    }
  }
}

.c-awards__year {
  display: flex;
  letter-spacing: .05em;

  @media #{md("sm", "max")} {
    z-index: 2;
    justify-content: flex-end;
    padding-top: .25em;
    padding-bottom: calc(.25em + #{$border-height});
    background-color: var(--color-bg);
    @include noise;

    &:before {
      @include pseudo-el($height: $border-height, $bg: currentColor);
      position: absolute;
      bottom: 0;
      left: 0;
      transform: scale(0, 1);
      transform-origin: 100% 50%;
      transition: transform .8s $out-quint;
    }

    &.is-sticky {

      &:before {
        transform: scale(1);
      }
    }
  }

  @media #{md("sm")} {

    .o-at {
      transform: translate(0, calc(var(--scroll-progress) * (#{vh(50)} - 80%)));
    }
  }
}

.c-awards__list {
  @include reset-list;
  padding-top: $border-height/2;
  margin-bottom: -1em;
  font-weight: 300;
}

.c-awards__item {
}

.c-awards__link {
  position: static;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: .5em;
  align-items: baseline;
  padding-top: 1.75em;
  padding-bottom: 1.75em;
  font-size: .8em;
  text-transform: uppercase;
  transition: color .2s ease-out;

  &:before {
    @include pseudo-el($bg: var(--color-primary));
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1, 0);
    transform-origin: 0 100%;
    transition: transform .6s $out-quint;
  }

  &:hover {
    color: var(--color-bg);

    &:before {
      transform: scale(1);
    }

    .c-awards__icon {
      transform: translate(-.75rem) scale(1);
    }

    .c-awards__project {
      transform: translate(.75rem, 0);
    }
  }

  @media #{md("xs", "max")} {

    .c-awards__icon {
      top: .75em;
    }

    &:hover {

      .c-awards__label .o-at__inner {
        transform: translate(.75rem, 0);
      }
    }
  }

  @media #{md("xs")} {
    grid-template-columns: 1.5fr 1.5fr;
  }
}

.c-awards__project {
  font-weight: 400;
  transition: transform .6s $out-quint;
}

.c-awards__label {
  position: static;
  font-weight: 300;

  &:after {
    @include pseudo-el($height: 1px, $bg: currentColor);
    position: absolute;
    bottom: 0;
    left: 0;
    transform: scale(0, 1) translate(0, vh(10));
    transform-origin: 0 50%;
    will-change: transform;
  }

  &.is-revealed,
  &.is-revealing {

    &:after {
      transform: scale(1) translate(0);
      transition: transform .8s $out-quint;
    }
  }

  @media #{md("xs", "max")} {

    .o-at__inner {
      transition: transform .6s $out-quint;
    }
  }
}

.c-awards__icon {
  position: absolute;
  top: calc(50% - (1.2em/2) * 11/19);
  right: 0;
  color: var(--color-bg);
  transform: translate(0) scale(0);
  transform-origin: 100% 50%;
  transition: transform .6s $out-quint;
}

</style>
