<template>
  <i :class="className">
    <svg :class="`svg-${icon}`">
      <title v-if="title">{{ title }}</title>
      <use v-bind:xlink:href="path"></use>
    </svg>
  </i>
</template>

<script>

export default {
  name: 'Icon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    path() {
      // return `/public_html/svg/sprite.svg#svg-${this.icon}`;

      let icon = require(`@/assets/svg/${this.icon}.svg`);
      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default;
      }

      return icon.url;
    },
    className() {
      let classname = `o-icon -${this.icon}`
      return classname;
    }
  },
};

</script>

<style lang="scss">

.o-icon {
  display: inline-block;

  svg {
    display: block;
    fill: currentColor;
  }
}


/*----------  SVG Sizes  ----------*/

// Logo
.svg-smiley {
  width: var(--icon-size, var(--icon-default-size));
  height: var(--icon-size, var(--icon-default-size));
}

// Arrows
.svg-arrow-up {
  --icon-default-size: 1em;

  width: calc(var(--icon-size, var(--icon-default-size)) * 11/19);
  height: var(--icon-size, var(--icon-default-size));
}

.svg-arrow-right {
  --icon-default-size: 1em;

  width: var(--icon-size, var(--icon-default-size));
  height: calc(var(--icon-size, var(--icon-default-size)) * 11/19);
}

.svg-arrow-up-right {
  --icon-default-size: .8em;

  width: var(--icon-size, var(--icon-default-size));
  height: calc(var(--icon-size, var(--icon-default-size)));
}



</style>
